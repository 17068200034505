// TODO: Remove django_app from meta data, and all instances in the MWA when we have
// finsihed moving all campaigns to engagemnts.
// TODO: remove is_spark_engagement when we have finished moving all MWA V1 surveys (campaigns) to
// MWA V2 surveys (engagements) -- with the updated survey styling etc...
export default {
  AEP_2024: {
    base_url: '/engagements/aep2024',
    base_route_name: 'engagements-aep2024',
    is_spark_engagement: false,
    slug: 'AEP_2024',
    slug_simple: 'aep2024',
    django_app: 'engagements',
  },
  AEP_2024_2: {
    base_url: '/engagements/aep2024-2',
    base_route_name: 'engagements-aep2024-2',
    is_spark_engagement: false,
    layout: 'LayoutSurveyV2',
    slug: 'AEP_2024_2',
    slug_simple: 'aep2023-2',
    django_app: 'engagements',
  },
  NEEDS_ASSESSMENT_V2_CLIENT: {
    base_url: '/engagements/needs-assessment',
    base_route_name: 'engagement-needs-assessment',
    is_spark_engagement: false,
    layout: 'LayoutSurvey',
    slug: 'NEEDS_ASSESSMENT_V2_CLIENT',
    slug_simple: 'needs-assessment',
    django_app: 'engagements',
  },
  NEEDS_ASSESSMENT_V2_PROSPECT: {
    base_url: '/engagements/needs-assessment',
    base_route_name: 'engagement-needs-assessment',
    is_spark_engagement: false,
    layout: 'LayoutSurvey',
    slug: 'NEEDS_ASSESSMENT_V2_PROSPECT',
    slug_simple: 'needs-assessment',
    django_app: 'engagements',
  },
  NEEDS_ASSESSMENT: {
    base_url: '/engagements/needs-assessment-v3',
    base_route_name: 'engagement-needs-assessment-v3',
    is_spark_engagement: false,
    layout: 'LayoutSurvey',
    slug: 'NEEDS_ASSESSMENT',
    slug_simple: 'needs-assessment-v3',
    django_app: 'engagements',
  },
  INTAKE_FORM: {
    base_url: '/engagements/intake-form',
    base_route_name: 'engagement-intake-form',
    is_spark_engagement: false,
    layout: 'LayoutSurvey',
    slug: 'INTAKE_FORM',
    slug_simple: 'intake-form',
    django_app: 'engagements',
  },
  REFERRAL_INITIATION_2023: {
    base_url: '/engagements/referrer',
    base_route_name: 'engagement-referrer',
    is_spark_engagement: true,
    layout: 'LayoutSurvey',
    slug: 'REFERRAL_INITIATION_2023',
    slug_simple: 'referrer',
    django_app: 'engagement',
  },
  REFERRAL_RESPONSE_2023: {
    base_url: '/engagements/referral',
    base_route_name: 'engagement-referral',
    is_spark_engagement: false,
    layout: 'LayoutSurvey',
    slug: 'REFERRAL_RESPONSE_2023',
    slug_simple: 'referral',
    django_app: 'engagement',
  },
  SOA: {
    base_url: '/engagements/soa',
    base_route_name: 'engagement-soa',
    is_spark_engagement: false,
    layout: 'LayoutNoSurvey',
    slug: 'SOA',
    slug_simple: 'soa',
    django_app: 'engagement',
  },
  PTC_FORM: {
    base_url: '/engagements/ptc-form',
    base_route_name: 'engagement-ptc-form',
    is_spark_engagement: false,
    layout: 'LayoutSurveyNoAuth',
    slug: 'PTC_FORM',
    slug_simple: 'ptc-form',
    django_app: 'engagement',
  },
};
