import engagementMetas from '@/constants/engagementMetas';
import { userRole } from '@/constants/enums';

const BASE_URL = engagementMetas.AEP_2024_2.base_url;
const BASE_ROUTE_NAME = engagementMetas.AEP_2024_2.base_route_name;
const AUTH_TRUE = {
  roles: [userRole.MEMBER],
};
const LAYOUT = engagementMetas.AEP_2024_2.layout;
const SLUG_SIMPLE = engagementMetas.AEP_2024_2.slug_simple;
const DJANGO_APP = engagementMetas.AEP_2024_2.django_app;

export default [
  // auth
  {
    path: `${BASE_URL}/auth/:token`,
    name: `${BASE_ROUTE_NAME}-auth`,
    component: () => import(`@/views${BASE_URL}/auth/Index.vue`),
    meta: {
      layout: 'LayoutSurveyNoAuth',
      slug_simple: SLUG_SIMPLE,
      title: 'Login',
      django_app: DJANGO_APP,
    },
  },
  // end auth

  // shared pages
  {
    path: `${BASE_URL}/pages/:pageId?`,
    name: `${BASE_ROUTE_NAME}-pages`,
    component: () => import('@/components/engagement-surveys/sections-page/IndexV2.vue'),
    meta: {
      auth: AUTH_TRUE,
      layout: LAYOUT,
      slug_simple: SLUG_SIMPLE,
      title: 'Survey section',
      django_app: DJANGO_APP,
    },
  },

  // unique pages
  {
    path: `${BASE_URL}/complete`,
    name: `${BASE_ROUTE_NAME}-complete`,
    component: () => import(`@/views${BASE_URL}/complete/Index.vue`),
    meta: {
      auth: AUTH_TRUE,
      layout: 'LayoutSurvey',
      slug_simple: SLUG_SIMPLE,
      title: 'Thank you',
      django_app: DJANGO_APP,
    },
  },
];
