<template>
  <component
    :is="layout"
  >
    <slot />
  </component>
</template>

<script>
import {
  defineAsyncComponent, markRaw, ref, watch,
} from 'vue';
import { useRoute } from 'vue-router';

const LayoutSurvey = defineAsyncComponent(() => import('@/layouts/LayoutSurvey.vue'));

export default {
  name: 'AppLayout',

  setup() {
    const layout = ref();
    const route = useRoute();

    watch(
      () => route.meta,
      async (meta) => {
        try {
          const component = meta && meta.layout && await import(`@/layouts/${meta.layout}.vue`);
          layout.value = markRaw(component?.default || LayoutSurvey);
        } catch {
          layout.value = markRaw(LayoutSurvey);
        }
      },
      { immediate: true },
    );
    return { layout };
  },
};
</script>
