const defaultStatuses = [404, 408, 503];

/**
 * Method for returning default error messages.
 * @param {*} param0 - api response data
 * @returns object of error data and message
 */
const getDefaultErrorMessage = ({ data, status }) => {
  switch (status) {
    case 404:
      return {
        ...data,
        is_api_msg: !!data.message,
        key: 'errorMsg404',
        message: data.message || 'Not Found',
      };
    case 408:
      return {
        ...data,
        is_api_msg: !!data.message,
        key: 'errorMsg408',
        message: data.message || 'Connection error',
      };
    case 503:
      return {
        ...data,
        is_api_msg: !!data.message,
        key: 'errorMsg503',
        message: data.message || 'Our servers are currently down',
      };
    default:
      return {
        ...data,
        is_api_msg: !!data.message,
        key: 'apiError',
        message: data.message || 'Oops! Something went wrong',
      };
  }
};

/**
 * Handles api errors by standardizing error format and messages.
 * @param {*} error - api error
 * @returns Newly formatted error object
 */
const handleApiError = (error) => {
  if (error.response) {
    if (typeof error.response.data !== 'object') error.response.data = {}; // if not object, then its not custom api.
    error.response.data = {
      ...getDefaultErrorMessage(error.response),
    };
  } else {
    error.response = {
      data: {
        is_api_msg: false,
        key: 'networkErrorMsg',
        message: 'A network error occurred',
      },
    };
  }
  return error;
};

// eslint-disable-next-line import/prefer-default-export
export { handleApiError };
