import { createAuth } from '@websanova/vue-auth/src/v3';
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x';
import axios from '@/plugins/axios';
import router from '@/router/index';
import engagementMetas from '@/constants/engagementMetas';
import { userRole } from '@/constants/enums';
import driverJwt from './jwt_driver';

const appAuth = createAuth({
  plugins: {
    http: axios,
    router,
  },
  drivers: {
    auth: driverJwt,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    rolesKey: 'roles',
    refreshTokenName: 'spark_refresh_token',
    tokenDefaultKey: 'spark_token_default',
    // default for when un-authenticated user try to enter an auth:true route
    authRedirect: { name: 'campaign-401' },
    // default for when authenticated user try to enter an auth:false route
    notFoundRedirect(transition) {
      let engagementMeta;
      if (transition.slug_simple) {
        Object.values(engagementMetas).forEach((value) => {
          if (value.slug_simple === transition.slug_simple) {
            engagementMeta = value;
          }
        });
      }

      const routeObj = { name: 'root' };
      if (engagementMeta) {
        routeObj.name = `${engagementMeta.base_route_name}-landing`;
        const token = transition.params?.token;
        if (token) {
          routeObj.params = { token };
        }
      }
      return routeObj;
    },
    loginData: {
      url: 'members/engagements/authentication/',
      fetchUser: true,
      redirect: false,
      staySignedIn: true,
    },
    fetchData: {
      url: 'members/engagements/user/',
    },
    logoutData: {
      redirect: false,
      makeRequest: false,
    },
    refreshData: {
      url: 'members/token/refresh/',
      method: 'POST',
    },
    parseUserData(data) {
      if (data) return { ...data, roles: [userRole.MEMBER] };
      return {};
    },
  },
});

export default appAuth;
