export default {
  request(req, token) {
    if (req.url === this.options.refreshData.url) {
      // Set the refresh token in the body
      req.data = { refresh: this.token(this.options.refreshTokenName) };
    }
    this.drivers.http.setHeaders.call(this, req, {
      Authorization: `Bearer ${token}`,
    });
  },

  response(res) {
    const data = this.drivers.http.httpData.call(this, res);
    if (data && data.access && data.refresh) {
      const token = data.access;
      this.token(this.options.refreshTokenName, data.refresh);
      return token;
    }

    if (res.status === 401) {
      this.logout();
      return null;
    }

    return null;
  },
};
