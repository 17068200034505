// eslint-disable-next-line import/no-unresolved
import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import { scrollBehavior, setPageTitle } from './utility';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior,
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.meta.fromData) {
    delete from.meta.fromData;
  }
  to.meta.fromData = from;

  setPageTitle(to);
  next();
});

export default router;
