/* eslint-disable import/no-cycle */
/**
 * This plugin allows axios to be avaliable on the global scope in a vue component
 * usage: this.$axios
 */
import axios from 'axios';
import router from '@/router/index';

import * as Sentry from '@sentry/vue';
import { handleApiError } from '../../../js/utilities/default-api-errors';

let baseURL = '/api';
if (process.env.NODE_ENV === 'development' || process.env.VUE_APP_PROD_OVERRIDE) {
  if (process.env.VUE_APP_API_OVERRIDE) {
    baseURL = process.env.VUE_APP_API_OVERRIDE;
  } else {
    let port = '';
    if (process.env.VUE_APP_NGINX_PORT) {
      port = `:${process.env.VUE_APP_NGINX_PORT}`;
    }
    baseURL = `http://localhost${port}/api`;
  }
}

const instance = axios.create({
  baseURL,
});

const enableSentry = ['uat', 'production'].includes(process.env.SENTRY_ENV);

const AUTH_ENDPOINTS = ['members/engagements/authentication/', 'members/engagements/user/'];

instance.interceptors.request.use(async (config) => {
  const { useAuthStore } = await import('@/store/auth-store');
  const authStore = useAuthStore();

  const token = authStore.getAuthToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const djangoApp = router.currentRoute.value.meta.django_app;
  if (config.url && djangoApp) {
    if (djangoApp === 'campaigns') {
      // special cases
      // TODO: Can remove when all MWAV2 campaigns are transitioned to engagements app.
      if (config.url.includes('engagements/engagement-files')) {
        config.url = config.url.replace('engagement-files', 'survey-files');
      }

      if (config.url.includes('members/engagements')) {
        // Updated members url being called from MWA V2 but django_app is campaigns.
        config.url = config.url.replace('engagements/', '');
      } else if (config.url.includes('engagements')) {
        // Updated engagements url being called from MWA V2 but django_app is campaigns.
        config.url = config.url.replace('engagements', 'campaigns');
      }
    }
  }
  return config;
}, (error) => Promise.reject(error));

instance.interceptors.response.use((response) => response, async (error) => {
  if (enableSentry) {
    Sentry.captureException(error);
  }
  if (error.response?.status === 401 || error.response?.status === 403) {
    const originalRequest = error.config;
    if (!AUTH_ENDPOINTS.includes(originalRequest.url) && window.location.href !== '/campaigns/error/401') {
      const { useAuthStore } = await import('@/store/auth-store');
      const authStore = useAuthStore();
      if (!originalRequest._retry && !originalRequest.url.includes('token/refresh/')) {
        originalRequest._retry = true;
        try {
          const newToken = await authStore.refreshToken();
          // update request header
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return instance(originalRequest);
        } catch (refreshError) {
        // If token refresh fails, redirect to the error page
          router.push({ name: 'campaign-401' });
          return Promise.reject(refreshError);
        }
      } else {
        router.push({ name: 'campaign-401' });
      }
    }
  }
  return Promise.reject(handleApiError(error));
});

export default instance;
