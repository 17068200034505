<template>
  <div
    id="app"
  >
    <AppLayout>
      <router-view v-slot="{ Component }">
        <transition
          name="page"
          mode="out-in"
        >
          <component :is="Component" />
        </transition>
      </router-view>
    </AppLayout>
    <div id="modal-target" />
  </div>
</template>

<script>
import { watch } from 'vue';
import { useHead } from '@vueuse/head';
import { useToast } from 'vue-toastification';
import { useRoute } from 'vue-router';
import smoothscroll from 'smoothscroll-polyfill';
import AppLayout from '@/layouts/AppLayout.vue';

smoothscroll.polyfill();

export default {
  components: {
    AppLayout,
  },

  setup() {
    useHead({
      link: [{
        rel: 'preconnect',
        href: 'https://rsms.me/',
      }, {
        rel: 'stylesheet',
        href: 'https://rsms.me/inter/inter.css',
      },
      ],
      meta: [
        {
          name: 'norton-safeweb-site-verification',
          content: 'ph973g2si7-02i5hj8v48i2-u6owkj288-bs7n3adhfvk0e2zye0502ahtxbrgj2-uijm5pmek5a-mptups0u2nrhm0w6h0lee67o9-ufr80wnxixt57l9mpq4hs6fmq',
        },
        {
          name: 'apple-mobile-web-app-title',
          content: 'Spark Advisors',
        },
        {
          name: 'application-name',
          content: 'Spark Advisors',
        },
        {
          name: 'theme-color',
          content: '#ffffff',
        },
      ],
    });
    const toast = useToast();

    const route = useRoute();
    watch(
      () => route.params,
      async (params) => {
        const { toast_type, toast_message } = params;
        // eslint-disable-next-line camelcase
        if (toast_message) {
          // eslint-disable-next-line camelcase
          if (toast_type === 'success') {
            toast.success(toast_message, { icon: 'success-icon' });
          } else {
            toast.error(toast_message);
          }
        }
      },
    );

    return { toast };
  },
};
</script>

<style>
/* need to update router/utility/scrollBehavior if duration changes */
.page-enter-active,
.page-leave-active {
  transition: opacity 0.35s ease;
}

.page-enter-from,
.page-leave-active {
  opacity: 0;
}
</style>
