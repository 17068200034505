import engagementMetas from '@/constants/engagementMetas';
import { userRole } from '@/constants/enums';

const AUTH_TRUE = {
  roles: [userRole.MEMBER],
};
const BASE_URL = engagementMetas.AEP_2024.base_url;
const BASE_ROUTE_NAME = engagementMetas.AEP_2024.base_route_name;
const SLUG_SIMPLE = engagementMetas.AEP_2024.slug_simple;
const DJANGO_APP = engagementMetas.AEP_2024.django_app;

export default [
  {
    path: `${BASE_URL}/complete`,
    name: `${BASE_ROUTE_NAME}-complete`,
    component: () => import('@/views/engagements/aep2024-2/complete/Index.vue'),
    meta: {
      auth: AUTH_TRUE,
      layout: 'LayoutSurvey',
      slug_simple: SLUG_SIMPLE,
      title: 'Thank you',
      django_app: DJANGO_APP,
    },
  },
  {
    path: `${BASE_URL}/:token/:step?`,
    name: `${BASE_ROUTE_NAME}-landing`,
    component: () => import(`@/views${BASE_URL}/Index.vue`),
    meta: {
      layout: 'LayoutSurveyNoAuth',
      slug_simple: SLUG_SIMPLE,
      title: 'Your survey',
      django_app: DJANGO_APP,
    },
  },
];
