import engagementMetas from '@/constants/engagementMetas';
import { userRole } from '@/constants/enums';

const BASE_URL = engagementMetas.SOA.base_url;
const BASE_ROUTE_NAME = engagementMetas.SOA.base_route_name;
const AUTH_TRUE = {
  roles: [userRole.MEMBER],
};
const LAYOUT = engagementMetas.SOA.layout;
const SLUG_SIMPLE = engagementMetas.SOA.slug_simple;
const DJANGO_APP = engagementMetas.SOA.django_app;

export default [
  // auth
  {
    path: `${BASE_URL}/auth/:token/:soa_pk`,
    name: `${BASE_ROUTE_NAME}-auth`,
    component: () => import(`@/views${BASE_URL}/auth/Index.vue`),
    meta: {
      auth: false,
      layout: 'LayoutSurveyNoAuth',
      slug_simple: SLUG_SIMPLE,
      title: 'Login',
      django_app: DJANGO_APP,
    },
  },
  // end auth
  {
    path: `${BASE_URL}/sign/:soa_pk`,
    name: `${BASE_ROUTE_NAME}-sign`,
    component: () => import(`@/views${BASE_URL}/sign/Index.vue`),
    meta: {
      auth: AUTH_TRUE,
      layout: LAYOUT,
      slug_simple: SLUG_SIMPLE,
      title: 'Your signature',
      django_app: DJANGO_APP,
    },
  },
  {
    path: `${BASE_URL}/complete/:soa_pk`,
    name: `${BASE_ROUTE_NAME}-complete`,
    component: () => import(`@/views${BASE_URL}/complete/Index.vue`),
    meta: {
      auth: AUTH_TRUE,
      layout: LAYOUT,
      slug_simple: SLUG_SIMPLE,
      title: 'Complete',
      django_app: DJANGO_APP,
    },
  },
  {
    path: `${BASE_URL}/:token/:soa_pk`,
    name: `${BASE_ROUTE_NAME}-landing`,
    component: () => import(`@/views${BASE_URL}/Index.vue`),
    meta: {
      layout: 'LayoutSurveyNoAuth',
      slug_simple: SLUG_SIMPLE,
      title: 'Scope of sales appointment',
      django_app: DJANGO_APP,
    },
  },
];
