import moduleEngagementAEP2024 from './modules/engagements/aep2024';
import moduleEngagementAEP2024Part2 from './modules/engagements/aep2024-2';
import moduleEngagementNeedsAssessment from './modules/engagements/needs-assessment';
import moduleReferral from './modules/engagements/referral';
import moduleReferrer from './modules/engagements/referrer';
import moduleSOA from './modules/engagements/soa';
import moduleEngagementNeedsAssessmentV3 from './modules/engagements/needs-assessment-v3';
import moduleEngagementIntakeForm from './modules/engagements/intake-form';
import moduleEngagementPTCForm from './modules/engagements/ptc-form';

export default [
  /*
      {
        path: '/test',
        name: 'test',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/test/Index.vue'),
      },
    */
  // {
  //   path: '/',
  //   name: 'root',
  //   beforeEnter(to, from, next) {
  //     window.location.replace('https://www.sparkadvisors.com/');
  //   },
  // },

  // generic error page for logging
  {
    path: '/engagements/error/',
    name: 'root',
    component: () => import('@/components/engagement-surveys/general-error/Index.vue'),
    meta: {
      title: 'Error',
      layout: 'LayoutEmpty',
    },
  },
  {
    // TODO: Keeping while aep2022 is still on campaigns app because of the url/path it uses
    //       Remove once we transfer final campaign surveys to engagements.
    path: '/campaigns/auth/staff/',
    name: 'campaign-auth-staff',
    component: () => import('@/components/v1-campaign-surveys/auth-page/staff/Index.vue'),
    meta: {
      title: 'Login Staff',
      layout: 'LayoutEmpty',
      django_app: 'campaigns',
    },
  },
  {
    path: '/engagements/auth/staff/',
    name: 'engagement-auth-staff',
    component: () => import('@/components/engagement-surveys/auth-page/staff/Index.vue'),
    meta: {
      title: 'Login Staff',
      layout: 'LayoutEmpty',
      django_app: 'engagements',
    },
  },
  {
    path: '/campaigns/error/401',
    name: 'campaign-401',
    component: () => import('@/components/engagement-surveys/401/Index.vue'),
    meta: {
      title: 'Redirect',
      layout: 'LayoutEmpty',
    },
  },

  // /campaigns/needs-assessment
  ...moduleEngagementNeedsAssessment,

  // /campaigns/needs-assessment-v3
  ...moduleEngagementNeedsAssessmentV3,

  // /engagements/intake-form
  ...moduleEngagementIntakeForm,

  // /engagements/aep2024
  ...moduleEngagementAEP2024,

  // /engagements/aep2024-2/
  ...moduleEngagementAEP2024Part2,

  // /engagements/referral
  ...moduleReferral,

  // /engagements/referrer
  ...moduleReferrer,

  // /engagements/ptc-form
  ...moduleEngagementPTCForm,

  // /campaigns/soa
  ...moduleSOA,

  // catch-all
  { path: '/:pathMatch(.*)*', name: 'NotFound', redirect: { name: 'root' } },
];
