import engagementMetas from '@/constants/engagementMetas';
import { userRole } from '@/constants/enums';

// NAv2 Client and Prospect share these fields, so just use client for both
const BASE_URL = engagementMetas.NEEDS_ASSESSMENT_V2_CLIENT.base_url;
const BASE_ROUTE_NAME = engagementMetas.NEEDS_ASSESSMENT_V2_CLIENT.base_route_name;
const AUTH_TRUE = {
  roles: [userRole.MEMBER],
};
const SLUG_SIMPLE = engagementMetas.NEEDS_ASSESSMENT_V2_CLIENT.slug_simple;
const DJANGO_APP = engagementMetas.NEEDS_ASSESSMENT_V2_CLIENT.django_app;

export default [
  // auth
  {
    path: `${BASE_URL}/auth/:token`,
    name: `${BASE_ROUTE_NAME}-auth`,
    component: () => import(`@/views${BASE_URL}/auth/Index.vue`),
    meta: {
      layout: 'LayoutSurveyNoAuth',
      slug_simple: SLUG_SIMPLE,
      title: 'Login',
      django_app: DJANGO_APP,
    },
  },
  // end auth
  {
    path: `${BASE_URL}/sections/:id/page/:pageId`,
    name: `${BASE_ROUTE_NAME}-sections`,
    component: () => import('@/components/engagement-surveys/sections-page/Index.vue'),
    meta: {
      auth: AUTH_TRUE,
      layout: 'LayoutSurvey',
      slug_simple: SLUG_SIMPLE,
      title: 'Survey section',
      django_app: DJANGO_APP,
    },
  },
  {
    path: `${BASE_URL}/overview`,
    name: `${BASE_ROUTE_NAME}-overview`,
    component: () => import('@/components/engagement-surveys/overview-page/Index.vue'),
    meta: {
      auth: AUTH_TRUE,
      layout: 'LayoutSurvey',
      slug_simple: SLUG_SIMPLE,
      title: 'Survey overview',
      django_app: DJANGO_APP,
    },
  },
  {
    path: `${BASE_URL}/prescriptions/add`,
    name: `${BASE_ROUTE_NAME}-prescription-add`,
    component: () => import('@/components/engagement-surveys/prescriptions-page/Index.vue'),
    meta: {
      auth: AUTH_TRUE,
      layout: 'LayoutSurvey',
      slug_simple: SLUG_SIMPLE,
      title: 'Add prescription',
      django_app: DJANGO_APP,
    },
  },
  {
    path: `${BASE_URL}/prescriptions/:id/edit`,
    name: `${BASE_ROUTE_NAME}-prescription-edit`,
    component: () => import('@/components/engagement-surveys/prescriptions-page/Index.vue'),
    meta: {
      auth: AUTH_TRUE,
      layout: 'LayoutSurvey',
      slug_simple: SLUG_SIMPLE,
      title: 'Edit prescription',
      django_app: DJANGO_APP,
    },
  },
  {
    path: `${BASE_URL}/pharmacies/add`,
    name: `${BASE_ROUTE_NAME}-pharmacies-add`,
    component: () => import('@/components/engagement-surveys/pharmacies-page/Index.vue'),
    meta: {
      auth: AUTH_TRUE,
      layout: 'LayoutSurvey',
      slug_simple: SLUG_SIMPLE,
      title: 'Add pharmacy',
      django_app: DJANGO_APP,
    },
  },
  {
    path: `${BASE_URL}/providers/add`,
    name: `${BASE_ROUTE_NAME}-providers-add`,
    component: () => import('@/components/engagement-surveys/providers-page/Index.vue'),
    meta: {
      auth: AUTH_TRUE,
      layout: 'LayoutSurvey',
      slug_simple: SLUG_SIMPLE,
      title: 'Add provider',
      django_app: DJANGO_APP,
    },
  },
  {
    path: `${BASE_URL}/complete`,
    name: `${BASE_ROUTE_NAME}-complete`,
    component: () => import(`@/views${BASE_URL}/complete/Index.vue`),
    meta: {
      auth: AUTH_TRUE,
      layout: 'LayoutSurvey',
      slug_simple: SLUG_SIMPLE,
      title: 'Thank you',
      django_app: DJANGO_APP,
    },
  },
  {
    path: `${BASE_URL}/:token`,
    name: `${BASE_ROUTE_NAME}-landing`,
    component: () => import(`@/views${BASE_URL}/Index.vue`),
    meta: {
      layout: 'LayoutSurveyNoAuth',
      slug_simple: SLUG_SIMPLE,
      title: 'Your survey',
      django_app: DJANGO_APP,
    },
  },
];
