import { createApp, markRaw } from 'vue';
import { createHead } from '@vueuse/head';
import { createPinia } from 'pinia';
import Toast, { POSITION } from 'vue-toastification';
import VueLoading from 'vue-loading-overlay';
import axios from '@/plugins/axios';
import VueGtag from 'vue-gtag';
import auth from './auth/index';
import router from './router';
import sentryInit from './sentry/index';
import App from './App.vue';

// styles
import './css/style.css';

/* To use:
import { useToast } from 'vue-toastification';
const toast = useToast();
this.toast.success('ok!');
*/
const toastOptions = {
  closeButton: false,
  icon: false,
  hideProgressBar: true,
  position: POSITION.TOP_CENTER,
  timeout: 2000, // duration
  toastClassName: 'spark-toast',
  transition: 'Vue-Toastification__fade',

};

// head meta
const head = createHead();

const app = createApp(App);
const pinia = createPinia();
pinia.use(({ store }) => { store.router = markRaw(router); });

sentryInit(app);

app.use(pinia);
app.use(router);
app.use(auth);
app.use(head);

// google analytics vue library documented here: https://matteo-gabriele.gitbook.io/vue-gtag/
app.use(VueGtag, {
  bootstrap: false, // don't bootstrap until we know user- see loadGoogleAnalytics module
}, router);

app.use(VueLoading, {
  color: 'rgb(19, 31, 90)',
  width: 80,
  height: 80,
});
app.use(Toast, toastOptions);
router.isReady()
  .then(() => {
    app.config.globalProperties.$axios = axios;
    app.mount('#app');
  });
