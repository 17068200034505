import engagementMetas from '@/constants/engagementMetas';
import { userRole } from '@/constants/enums';

const BASE_URL = engagementMetas.REFERRAL_RESPONSE_2023.base_url;
const BASE_ROUTE_NAME = engagementMetas.REFERRAL_RESPONSE_2023.base_route_name;
const AUTH_TRUE = {
  roles: [userRole.MEMBER],
};
const SLUG_SIMPLE = engagementMetas.REFERRAL_RESPONSE_2023.slug_simple;
const DJANGO_APP = engagementMetas.REFERRAL_RESPONSE_2023.django_app;

export default [
  {
    path: `${BASE_URL}/page-1`,
    name: `${BASE_ROUTE_NAME}-page-1`,
    component: () => import(`@/views${BASE_URL}/page-1/Index.vue`),
    meta: {
      layout: 'LayoutSurvey',
      auth: AUTH_TRUE,
      slug_simple: SLUG_SIMPLE,
      title: 'Referral',
      django_app: DJANGO_APP,
    },
  },
  {
    path: `${BASE_URL}/complete`,
    name: `${BASE_ROUTE_NAME}-complete`,
    component: () => import(`@/views${BASE_URL}/complete/Index.vue`),
    meta: {
      layout: 'LayoutSurvey',
      auth: AUTH_TRUE,
      slug_simple: SLUG_SIMPLE,
      title: 'Referral',
      django_app: DJANGO_APP,
    },
  },
  {
    path: `${BASE_URL}/:token`,
    name: `${BASE_ROUTE_NAME}-landing`,
    component: () => import(`@/views${BASE_URL}/Index.vue`),
    meta: {
      layout: 'LayoutSurveyNoAuth',
      slug_simple: SLUG_SIMPLE,
      title: 'Your survey',
      django_app: DJANGO_APP,
    },
  },
];
