import * as Sentry from '@sentry/vue';

const sentryInit = (app) => {
  if (!['uat', 'production'].includes(process.env.NODE_ENV)) {
    return;
  }
  const isUAT = process.env.NODE_ENV === 'uat';
  // In UAT, trace 100% of transactions; in prod, only 20%.
  const tracesSampleRate = isUAT ? 1.0 : 0.2;

  Sentry.init({
    app,
    dsn: 'https://c94af7020948e62ce096a9cdb3e51dc0@o617925.ingest.us.sentry.io/4507861646049280',
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    environment: process.env.NODE_ENV,
    tracesSampleRate,
  });
};

export default sentryInit;
