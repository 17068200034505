import engagementMetas from '@/constants/engagementMetas';
import { userRole } from '@/constants/enums';

const BASE_URL = engagementMetas.NEEDS_ASSESSMENT.base_url;
const BASE_ROUTE_NAME = engagementMetas.NEEDS_ASSESSMENT.base_route_name;
const AUTH_TRUE = {
  roles: [userRole.MEMBER],
};
const SLUG_SIMPLE = engagementMetas.NEEDS_ASSESSMENT.slug_simple;
const DJANGO_APP = engagementMetas.NEEDS_ASSESSMENT.django_app;

export default [
  // auth
  {
    path: `${BASE_URL}/auth/:token`,
    name: `${BASE_ROUTE_NAME}-auth`,
    component: () => import(`@/views${BASE_URL}/auth/Index.vue`),
    meta: {
      layout: 'LayoutSurveyNoAuth',
      slug_simple: SLUG_SIMPLE,
      title: 'Login',
      django_app: DJANGO_APP,
    },
  },
  // end auth

  {
    path: `${BASE_URL}/pages/:pageId?`,
    name: `${BASE_ROUTE_NAME}-pages`,
    component: () => import('@/components/engagement-surveys/sections-page/IndexV2.vue'),
    meta: {
      auth: AUTH_TRUE,
      layout: 'LayoutSurveyV2',
      slug_simple: SLUG_SIMPLE,
      title: 'Survey page',
      django_app: DJANGO_APP,
    },
  },
  {
    path: `${BASE_URL}/complete`,
    name: `${BASE_ROUTE_NAME}-complete`,
    component: () => import(`@/views${BASE_URL}/complete/Index.vue`),
    meta: {
      auth: AUTH_TRUE,
      layout: 'LayoutSurvey',
      slug_simple: SLUG_SIMPLE,
      title: 'Thank you',
      django_app: DJANGO_APP,
    },
  },
  {
    path: `${BASE_URL}/:token`,
    name: `${BASE_ROUTE_NAME}-landing`,
    component: () => import(`@/views${BASE_URL}/Index.vue`),
    meta: {
      layout: 'LayoutSurveyNoAuth',
      slug_simple: SLUG_SIMPLE,
      title: 'Your survey',
      django_app: DJANGO_APP,
    },
  },
];
