export const scrollBehavior = (to, from, savedPosition) => {
  // let hash render in DOM first
  if (to.hash) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          selector: to.hash,
          behavior: 'smooth',
        });
      }, 350);
    });
  }

  // scroll after page transition (300ms)'s fade out
  const position = savedPosition || { x: 0, y: 0 };
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(position);
    }, 250);
  });
};

export const setPageTitle = (to) => {
  let title = 'Spark Advisors';
  if (to.meta && to.meta.title) {
    title += ` - ${to.meta.title}`;
  }
  document.title = title;
};
