import engagementMetas from '@/constants/engagementMetas';
import { userRole } from '@/constants/enums';

const BASE_URL = engagementMetas.PTC_FORM.base_url;
const BASE_ROUTE_NAME = engagementMetas.PTC_FORM.base_route_name;
const AUTH_TRUE = {
  roles: [userRole.MEMBER],
};
const SLUG_SIMPLE = engagementMetas.PTC_FORM.slug_simple;
const DJANGO_APP = engagementMetas.PTC_FORM.django_app;

export default [
  {
    path: '/engagements/ptc-form/:agentNpn/form/:referralToken',
    name: `${BASE_ROUTE_NAME}-landing`,
    component: () => import(`@/views${BASE_URL}/Index.vue`),
    meta: {
      layout: 'LayoutStylingOnly',
      slug_simple: SLUG_SIMPLE,
      title: 'Survey page',
      django_app: DJANGO_APP,
    },
  },
  {
    path: `${BASE_URL}/:agentNpn/:referralToken/complete/:token`,
    name: `${BASE_ROUTE_NAME}-complete`,
    component: () => import(`@/views${BASE_URL}/complete/Index.vue`),
    meta: {
      layout: 'LayoutSurveyNoAuth',
      slug_simple: SLUG_SIMPLE,
      title: 'Thank you',
      django_app: DJANGO_APP,
    },
  },
];
